import {
  FacilitiesToggle,
  LayersDrawer,
  TLanguage,
  countryCodes,
  useFacilities,
  useGeoveloMap,
  useTracker,
} from '@geovelo-frontends/commons';
import { Box, BoxProps, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { WindowLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../context';
import useHeatmap from '../../hooks/map/heatmap';
import useQueryParams from '../../hooks/query-params';

import { LngLatBoundsLike } from '!maplibre-gl';

const mapId = 'map';
export const defaultBounds: LngLatBoundsLike = [8.56187094, 42.23367292, -5.56077119, 51.39124445];

function Map({
  location,
  path,
  ...props
}: { location: WindowLocation; path: string } & BoxProps): JSX.Element {
  const {
    map: {
      initialBounds,
      baseLayer,
      parkingSpacesShowed,
      reportsShowed,
      facilitiesShowed,
      heatmapShowed,
      hillshadesShowed,
      layersDrawerOpen,
    },
    geoagglo: { current: currentGeoagglo },
    poi: { categories: poiCategories, selectedCategories: selectedPoiCategories },
    user: { current: currentUser, allTimeHeatmapData, loadingAllTimeHeatmapData },
    actions: {
      setMap,
      setMapBounds,
      setMapZoom,
      setMapBaseLayer,
      toggleParkingSpacesOnMap,
      toggleReportsOnMap,
      toggleFacilitiesOnMap,
      toggleHeatmapOnMap,
      toggleHillshadesOnMap,
      openMapLayersDrawer,
      selectPoiCategories,
      openNewReportDialog,
      getUserAllTimeHeatmapData,
    },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const {
    initialized: mapInitialized,
    map,
    init: initMap,
    setBaseLayer,
  } = useGeoveloMap({
    currentLanguage: currentLanguage as TLanguage,
    currentUser,
    layersDrawerOpen,
    baseLayer,
    hillshadesShowed,
    smallDevice,
    onLayersControlToggle: openMapLayersDrawer,
    onGetCurrentPositionFailure: () =>
      enqueueSnackbar(t('commons.location_error'), { variant: 'error' }),
    onContribute: () =>
      currentUser
        ? openNewReportDialog(true)
        : navigate(`/${currentLanguage}/sign-in`, { state: { prevPath: path } }),
  });
  const {
    initialized: facilitiesInitialized,
    init: initFacilities,
    toggle: toggleFacilities,
  } = useFacilities(map);
  useHeatmap({
    displayTraces: false,
    displayH3: true,
    map,
    mapInitialized,
    baseLayer,
    heatmapData: heatmapShowed ? allTimeHeatmapData : undefined,
  });
  const { getMapInitialPosition, update: updateQueryParams } = useQueryParams(location.search);
  const { trackEvent } = useTracker();

  useEffect(() => {
    const position = getMapInitialPosition();

    initMap({
      container: mapId,
      ...(position ? { ...position } : { bounds: initialBounds || defaultBounds }),
      scaleControl: true,
      customZoomControls: true,
      locateControl: true,
      fullscreenControl: true,
      scrollZoom: true,
      contributeControl: true,
      layersControl: true,
    });

    return () => {
      setMap(null);
      setMapZoom(undefined);
      setMapBounds(undefined);
    };
  }, []);

  useEffect(() => {
    if (map) {
      setMap(map);

      initFacilities(facilitiesShowed);
      updateLocationQueryParams();
      updateZoneQueryParam();

      map.on('moveend', updateLocationQueryParams);
    }

    return () => {
      map?.off('moveend', updateLocationQueryParams);
    };
  }, [map]);

  useEffect(() => {
    updateZoneQueryParam();
  }, [currentGeoagglo]);

  useEffect(() => {
    if (mapInitialized && setBaseLayer) setBaseLayer(baseLayer);
  }, [baseLayer]);

  useEffect(() => {
    if (facilitiesInitialized) toggleFacilities(facilitiesShowed);
  }, [facilitiesShowed]);

  function updateZoneQueryParam() {
    if (currentGeoagglo && countryCodes.indexOf(currentGeoagglo.code) === -1)
      updateQueryParams({ geoagglo: currentGeoagglo });
    else updateQueryParams({ geoagglo: null });
  }

  function updateLocationQueryParams() {
    if (!map) return;
    const center = map.getCenter();
    const zoom = map.getZoom();
    const [[west, south], [east, north]] = map.getBounds().toArray();

    setMapZoom(zoom);
    setMapBounds({ north, east, south, west });
    updateQueryParams({ location: { center, zoom } });
  }

  if (typeof window === 'undefined') {
    return <Box flexGrow={1} {...props} sx={{ backgroundColor: '#f0f0e2', ...props.sx }} />;
  }

  return (
    <>
      <Box
        flexGrow={1}
        id={mapId}
        position="relative"
        {...props}
        sx={{ backgroundColor: '#f0f0e2', ...props.sx }}
      >
        <LayersDrawer
          enableHeatmapToggle
          enableHillshadesToggle
          enableReportsToggle
          baseLayer={baseLayer}
          enableFacilitiesToggle={smallDevice}
          facilitiesShowed={facilitiesShowed}
          heatmapShowed={heatmapShowed}
          hillshadesShowed={hillshadesShowed}
          layersShowed={false}
          loadingHeatmap={loadingAllTimeHeatmapData}
          onBaseLayerChange={(layer) => {
            setMapBaseLayer(layer);
            trackEvent('Base layer', 'Updated', layer);
          }}
          onClose={() => openMapLayersDrawer(false)}
          open={layersDrawerOpen}
          PaperProps={{ style: { position: 'absolute' } }}
          poiCategories={poiCategories}
          poiOptions={{ displayParkingSpaces: parkingSpacesShowed }}
          reportsShowed={reportsShowed}
          selectedPoiCategories={selectedPoiCategories}
          selectPoiCategories={selectPoiCategories}
          setPoiOptions={({ displayParkingSpaces }) =>
            toggleParkingSpacesOnMap(Boolean(displayParkingSpaces))
          }
          smallDevice={smallDevice}
          toggleFacilities={toggleFacilitiesOnMap}
          toggleHeatmap={(showed) => {
            if (showed) getUserAllTimeHeatmapData();
            toggleHeatmapOnMap(showed);
          }}
          toggleHillshades={toggleHillshadesOnMap}
          toggleReports={toggleReportsOnMap}
        />
        <Box
          bottom={68}
          display={{ xs: 'none', md: 'block' }}
          left={10}
          position="absolute"
          sx={{ pointerEvents: 'none' }}
          top={10}
          zIndex={1000}
        >
          <FacilitiesToggle
            facilitiesShowed={facilitiesShowed}
            sx={{ maxHeight: '100%', overflow: 'hidden' }}
            toggleFacilities={toggleFacilitiesOnMap}
          />
        </Box>
      </Box>
    </>
  );
}

export default Map;
